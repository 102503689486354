.login-form {
  background-color: #fefefecc;
  width: 480px;
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 40px;
  align-items: center; /* Center items horizontally */
  margin: 30px;
}

.title {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 40px;
}

.title p {
  margin-top: -20px;
  font-size: 14px;
  color: black;
}

.title h2 {
  font-size: 24px;
  color: #019bbb !important;
}

form {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 30px;
  margin-top: 20px; /* Reduced margin for better alignment */
}

form input {
  width: 450px;
  height: 30px;
  border-radius: 12px;
  border: 1px solid #019bbb; /* Purple border color */
  padding: 10px;
  outline: none;
}

form input:focus {
  border-color: #019bbb;
  box-shadow: 0 0 5px rgba(34, 158, 215, 0.5);
}

form button {
  width: 480px;
  height: 50px;
  border-radius: 12px;
  border: 1px solid;
  background-color: #019bbb;
  cursor: pointer;
  align-self: flex-start;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 470px;
  margin-top: -15px;
}

.checkbox-container div {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.checkbox {
  width: 20px;
  height: 20px;
}

.forgot-password {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: #019bbb;
}

.login-form button {
  background-color: #89a4aa;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #019bbb;
  color: white;
}

.separator {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center content horizontally */
  margin: 20px 0; /* Add some margin for spacing */
}

.separator hr {
  width: 100px;
  flex: 1; /* Allow the horizontal lines to expand and fill available space */
  border: none; /* Remove the default border */
  border-top: 1px solid #555; /* Add a custom border */
  margin: 0 10px; /* Add spacing around the "or" text */
}

.separator p {
  margin: 0; /* Remove default margin */
  padding: 0 10px; /* Optional: Add some padding around the text */
  color: #555; /* Set text color */
  font-size: 16px; /* Set font size */
  white-space: nowrap; /* Prevent the text from breaking to a new line */
}

.agent-login {
  margin-top: 20px;
  align-self: flex-start; /* Align to the top of the container */
}

.sign-login a {
  color: #019bbb;
  font: 1em;
  font-weight: bold;
}
.agent-btn-login {
  width: 480px; /* Match the width of the login button */
  height: 50px !important;
  border-radius: 12px;
  border: 1px solid #019bbb;
  background-color: #fefefe !important; /* Match the background color of the login button */
  color: #019bbb !important;
  font-size: 16px !important;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
}

.agent-btn-login:hover {
  background-color: #019bbb;
}

.policy-container {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 450px; /* Adjust width as needed */
  margin-top: 20px; /* Optional: Add margin if needed */
  text-decoration: underline;
  color: #019bbb;
  font-size: 14px;
}

@media (max-width: 768px) {
  .login-form {
    width: 80%;
    padding: 20px;
    margin-top: 150px;
  }

  form {
    width: 90%;
  }
  .checkbox-container {
    width: 100%;
    margin-left: 20px;
  }
  form input {
    width: 100%;
  }

  form button {
    width: 100%;
    margin-left: 10px;
  }

  .separator {
    margin: 10px 0;
  }

  .checkbox-container {
    width: 100%;
  }

  .agent-login {
    width: 90%;
  }

  .agent-btn-login {
    width: 90%;
  }

  .policy-container {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .login-form {
    margin-top: 150px;
    padding: 20px;
    height: auto;
  }

  form input {
    width: 100%;
  }

  form button {
    width: 100%;
  }

  .separator {
    margin: 10px 0;
  }

  .checkbox-container {
    width: 100%;
  }

  .agent-login {
    width: 100%;
  }

  .agent-btn-login {
    width: 100%;
  }

  .policy-container {
    width: 100%;
  }
}
