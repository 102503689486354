/* Responsive Styles */
@media (max-width: 768px) {
    .footer-section {
      padding: 0px 16px;
    }

    .footer-section .footer-container{
        display: flex;
        flex-direction: column;
    }

    .footer {
        flex-direction: column; 
        align-items: center;
    }
}

@media (max-width: 480px) {
    .footer-section {
        padding: 0px 16px;
    }

    .footer-section .footer-container{
        display: flex;
        flex-direction: column;
    }

    .footer {
        flex-direction: column; 
        align-items: center;
    }
}
