.booking-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.back-home {
    width: 100%;
    margin-bottom: 20px;
}

.back-home a {
    text-align: left;
    color: var(--body-text-gray);
    text-decoration: none;
}
.back-home a::before {
    content: "< ";
}
.back-home a:hover {
    color: var(--primary-blue);
}

.confirmation-content {
    max-width: 600px;
    text-align: center;
}

.confirmation-image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.confirmation-content h1 {
    font-size: 24px;
    color: var(--primary-blue);
    font-weight: bold;
    margin-bottom: 10px;
}

.confirmation-content h1 span {
    font-weight: normal;
}

.confirmation-content p {
    font-size: 16px;
    color: var(--body-text-gray);
    margin-top: 10px;
}

.confirmation-content p a {
    color: var(--primary-blue);
    text-decoration: none;
    font-weight: bold;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .confirmation-content .confirmation-image {
        width: 150px;
        height: 150px;
    }

    .confirmation-content h1 {
        font-size: 20px;
    }

    .confirmation-content p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .confirmation-content .confirmation-image {
        width: 120px;
        height: 120px;
    }

    .confirmation-content h1 {
        font-size: 18px;
    }

    .confirmation-content p {
        font-size: 12px;
    }
}

/* ------- summary -------------- */
.booking-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: var(--button-radius);
    max-width: 600px;
    margin: 10px auto;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

.booking-summary h4 {
    font-size: var(--heading-font-size);
    color: var(--text-color);
    margin-bottom: 20px;
}

.booking-summary .summary-details {
    width: 100%;
    background-color: var(--form-bg-color);
    padding: 20px;
    border: 3px solid var(--input-text-color);
    border-radius: var(--button-radius);
    margin-bottom: 20px;
}

.booking-summary .summary-item {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--input-text-color);
}
.booking-summary .summary-item:last-child {
    border-bottom: none;
}

.booking-summary .summary-item strong {
    display: block;
    font-size: var(--body-font-size);
    color: var(--text-color);
}

.booking-summary .summary-item p {
    font-size: var(--body-font-size);
    color: var(--body-text-gray);
    margin-top: 5px;
}

.booking-summary .summary-actions {
    display: flex;
    gap: 20px;
}

.booking-summary .btn-primary {
    background-color: var(--button-bg-blue);
    color: var(--button-text-white);
    border: none;
    border-radius: var(--button-radius);
    padding: 20px 20px;
    font-size: var(--button-font-size);
    cursor: pointer;
}

.booking-summary .btn-secondary {
    background-color: transparent;
    color: var(--primary-blue);
    border: 2px solid var(--primary-blue);
    border-radius: var(--button-radius);
    padding: 20px 20px;
    font-size: var(--button-font-size);
    cursor: pointer;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .booking-summary .summary-actions {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .booking-summary .btn-primary,
    .btn-secondary {
        width: 100%;
        text-align: center;
    }

    .booking-summary .summary-details {
        padding: 15px;
    }

    .booking-summary h2 {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .booking-summary  .btn-primary,
    .booking-summary  .btn-secondary {
        font-size: 14px;
        padding: 8px 16px;
    }

    .booking-summary .summary-item p {
        font-size: 14px;
    }
}
