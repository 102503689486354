@tailwind base;
@tailwind components;
@tailwind utilities;

#faqs-page .hero-section {
    background-image: url("../images/about-us-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 85vh;
}
#faqs-page .header-section {
    height: 50vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
#faqs-page .header .heading{
    font-size: 36px;
    color: #FEFEFE;
    font-weight: bold;
}

.accordion-item{
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.19);
}