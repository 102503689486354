.view-tour {
    --primary: #019bbb;
    background-color: #f7f7f7;
    padding: 2rem;
}
.picnic-pizza-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
}

.text-content {
    flex-grow: 1;
    max-width: 70%;
}

.cities-link {
    color: #019bbb;
    text-decoration: none;
}

.cities-link:hover {
    text-decoration: underline;
}

.rating-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 40%;
}

.rating-content .star {
    color: #019bbb;
    font-size: 1.5em;
    margin-right: 5px;
}

.reviews {
    color: #ababab;
    font-size: 0.9em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .picnic-pizza-component {
        flex-direction: column;
        align-items: flex-start;
    }

    .rating-content {
        justify-content: flex-start;
        margin-top: 10px;
    }

    .text-content,
    .rating-content {
        max-width: 100%;
    }
}

/* ------------------------------------ */
.picnic-pizza-grid {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;
    align-items: center;
}

.large-image {
    grid-column: span 3;
    grid-row: span 2;
}

.small-image img,
.large-image img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover fit;
    border-radius: 12px;
}

.small-image:last-child {
    position: relative;
    overflow: hidden;
}
.picnic-pizza-grid button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    background-color: rgba(254, 254, 254, 0.8);
    border: none;
    /* padding: 1rem 2rem; */
    width: 134px;
    height: 40px;
    color: var(--primary-blue);
    border-radius: var(--button-radius);
    font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .picnic-pizza-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .picnic-pizza-grid {
        grid-template-columns: 1fr;
    }

    .large-image {
        grid-column: span 1;
    }
}

/* -------------------------------- */

.trip-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    padding: 1rem;
}

.trip-info {
    width: 60%;
    padding-right: 20px;
}

.trip-sidebar {
    /* width: 35%; */
    background-color: #fefefe;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
}

.trip-description {
    color: var(--text-color);
    font-size: var(--body-font-size);
    margin-bottom: 20px;
}

/* Button styles */
.fitn {
    display: flex;
    gap: 1rem;
    width: 70%;
}
.fitn-btn-primary {
    background-color: var(--button-text-white);
    flex-grow: 1;
    color: var(--primary-blue);
    border: 2px solid var(--primary-blue);
    padding: 1rem 2.5rem;
    border-radius: var(--button-radius);
    font-size: var(--button-font-size);
    cursor: pointer;
}

.fitn-btn-secondary {
    flex-grow: 1;
    background-color: var(--form-bg-color);
    color: var(--body-text-gray);
    border: 2px solid var(--body-text-gray);
    padding: 1rem 2.5rem;
    font-size: var(--button-font-size);
    border-radius: var(--button-radius);
    cursor: pointer;
}

.trip-itinerary .book-now {
    color: var(--button-text-white);
    background: var(--primary-blue);
    border: none;
    outline: none;
    width: 136px;
    /* aspect-ratio: 2/0.5; */
    height: 57px;
    border-radius: var(--button-radius);
}

/* navbar */
.trip-navbar {
    padding: 20px 0;
    margin-bottom: 20px;
}

.nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav-item {
    margin-right: 20px;
    font-size: 16px;
    cursor: pointer;
    color: #6d6d6d;
    position: relative;
}

.nav-item.active {
    color: var(--primary-blue);
}

.nav-item.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--primary-blue);
    bottom: -5px;
    left: 0;
}

.nav-item:hover {
    color: var(--primary-blue);
}

@media (max-width: 768px) {
    .nav-list {
        justify-content: space-around;
    }

    .nav-item {
        font-size: 14px;
        margin-right: 10px;
    }
    .fitn {
        width: 100%;
    }
}

/* Sidebar form */
.trip-sidebar h3 {
    color: var(--primary-blue);
    margin-bottom: 15px;
}
.trip-sidebar p {
    color: var(--body-text-gray);
}
.trip-sidebar .titles {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    gap: 0.4rem;
}

.selection-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid var(--body-text-gray);
    background-color: var(--form-bg-color);
    border-radius: var(--button-radius);
    padding: 10px 20px;
    width: 80%;
    max-width: 400px;
    height: 69px;
    margin: 2rem auto;
}

.selection-item {
    display: flex;
    align-items: center;
    color: var(--body-text-gray);
    font-size: 16px;
    border: none;
    background: #fefefe;
    cursor: pointer;
}

.selection-item .icon {
    font-size: 30px;
    margin-right: 8px;
    color: var(--body-text-gray);
}

.divider {
    height: 24px;
    width: 1px;
    background-color: var(--body-text-gray);
}

@media (max-width: 400px) {
    .selection-box {
        flex-direction: column;
        align-items: flex-start;
    }

    .divider {
        display: none;
    }

    .selection-item {
        margin-bottom: 10px;
    }
}
.booking-buttons {
    text-align: center;
    margin-top: 20px;
}

.booking-buttons .btn-primary {
    background-color: var(--primary-blue);
    color: var(--button-text-white);
    border: none;
    border-radius: var(--button-radius);
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    width: 80%;
    max-width: 440px;
    margin-bottom: 15px;
}

.booking-buttons .btn-primary:hover {
    opacity: 0.9;
}
.booking-buttons .btn-secondary {
    background-color: #fefefe;
    color: var(--primary-blue);
    border: 2px solid var(--primary-blue);
    border-radius: var(--button-radius);
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    width: 80%;
    max-width: 440px;
    margin-bottom: 20px;
}

.booking-buttons .btn-secondary:hover {
    background-color: var(--primary-blue);
    color: var(--form-bg-color);
}

.total-section {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    border-top: 1px solid var(--body-text-gray);
    padding-top: 20px;
}

.total-section p {
    color: var(--body-text-gray);
    font-size: 16px;
    margin: 0;
}

.total-section h2 {
    color: var(--primary-blue);
    font-size: 24px;
    margin-top: 5px;
}

/* Responsive */
@media (max-width: 768px) {
    .btn-primary,
    .btn-secondary {
        max-width: 100%;
    }
}

.total-price {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: var(--form-bg-color);
    padding: 20px;
    border-radius: var(--button-radius);
    max-width: 300px;
    width: 100%;
}

.date-modal-content {
    background-color: var(--form-bg-color);
    padding: 20px;
    border-radius: var(--button-radius);
    max-width: max-content;
    width: 100%;
}

.traveler-selection {
    text-align: center;
}

.traveler-options > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 1rem;
    border-bottom: 2px solid var(--body-text-gray);
}
.traveler-options h4 {
    color: #1e1e1e;
    margin: 0;
}

.traveler-options p {
    margin: 0;
}

.traveler-options .traveler-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
}
.traveler-options button {
    background-color: var(--button-text-white);
    border-radius: var(--button-radius);
    padding: 5px 10px;
    cursor: pointer;
}
.traveler-options button:nth-of-type(even) {
    color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
}

/* Trip Itinerary and Outline */
.trip-itinerary,
.trip-outline {
    margin-top: 2.5rem;
}

.trip-itinerary h2,
.trip-outline h2 {
    font-size: var(--heading-font-size);
    color: var(--text-color);
    margin-bottom: 15px;
}

.itinerary {
    margin: 0 auto 1rem;
    width: 95%;
    padding: 1rem;
    border-radius: var(--button-radius);
    background-color: #fefefe;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1)
}

.itinerary .day-details {
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;
    padding: 1rem 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.itinerary .day-details:last-child{
  border-bottom: none;
}

.itinerary .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font-size: 1.2rem; */
    font-weight: bold;
    color: var(--text-color);
    margin: 0;
}
.itinerary .accordion-header > div {
    width: 80%;
}


.itinerary .accordion-header span:first-child {
    color: var(--primary-blue);
}
.itinerary .accordion-header time {
    color: #ababab;
    line-height: 18px;
    font-weight: 300;
  }
  .itinerary .day-details .accordion-header > .icon {
  color: #ababab;
  font-size: 2rem;
}
.itinerary .accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    padding-left: .5rem;
}
.itinerary h4 {
  color: var(--primary-blue);
  margin-bottom: 0;
}
.itinerary .activity{
  padding: 1rem 0.3rem;
  background-color: #019cbb56;
}
.itinerary .hotel{
  margin-top: 2px;
}

.itinerary .accordion-content.show {
    max-height: max-content;
    opacity: 1;
}

.important-note {
    color: var(--body-text-gray);
}

.important-note span {
    color: rgb(204, 73, 73);
}
/* Responsive Design */
@media (max-width: 1024px) {
    .trip-info {
        width: 100%;
    }

    .trip-sidebar {
        width: 60%;
        margin-top: 20px;
    }

    /* .trip-sidebar .booking-buttons, .trip-sidebar .btn-secondary{
        margin: .4rem;
    } */
}

@media (max-width: 768px) {
    .view-tour {
        padding: 1rem;
    }
    .trip-page {
        flex-direction: column;
    }

    .trip-info,
    .trip-sidebar {
        width: 80%;
        margin: 0 5px;
    }
}
