@tailwind base;
@tailwind components;
@tailwind utilities;

#top-section {
  background-image: url('../../images/about-us-background.png');
}

#read-blog {
  background-image: url('../../images/ImportedPhoto.749976033.032907.jpeg');
}
