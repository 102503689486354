/* General container */
.booking-tour-container {
    margin: 20px auto 0;
    padding: 0px 20px 20px;
    background-color: #f7f7f7;
}

/* Navigation bar */
.booking-nav {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    width: 100%;
    background-color: var(--primary-blue);
    border-radius: 10px;
    margin-bottom: 20px;
}

.booking-nav ul {
    display: flex;
    gap: 30px;
    padding: 8px;
    list-style: none;
    margin: 0;
}

.booking-nav > ul > li {
    color: var(--form-bg-color);
    cursor: pointer;
    position: relative;
}

.booking-nav > ul > li.active {
    font-weight: 600;
}

/* Booking content */
.booking-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

/* tour date */
.tour-dates {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tour-dates h2 {
    font-size: 16px;
    font-weight: bold;
}

.booking-calender {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

/* tour date end here */

.your-booking {
    background-color: var(--form-bg-color);
    padding: 40px;
    margin-left: 2rem;
    width: 40%;
    height: 50%;
    margin-top: 2rem;
    border-radius: 10px;
}

.currency-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: .5rem;
    align-items: center;
}
.currency-select h2{
    font-size: clamp(1rem, 3vw, 2rem);
}

.custom-select {
    position: relative;
    width: 150px;
    font-family: Arial, sans-serif;
}

.selected-country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 12px;
    cursor: pointer;
}
.selected-country div {
    display: flex;
    align-items: center;
}
.selected-country img {
    margin-right: 0.6rem;
    width: 30px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}

.selected-country:last-child{
    /* font-size: 30px; */
    color: red;
}

.dropdown {
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    height: 300px;
    overflow-y: scroll;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1;
}

.custom-select:is(:hover, :focus) .dropdown {
    display: block;
}

.dropdown-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.dropdown-option img {
    margin-right: 8px;
}

.dropdown-option:hover {
    background-color: #f1f1f1;
}

.custom-select .dropdown-option:first-child {
    border-top: none;
}

/* Booking details */
.booking-details {
    margin-top: 20px;
    font-size: 16px;
    color: #555;
}

.booking-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.booking-row label {
    font-size: 16px;
    color: var(--body-text-gray);
    flex: 1;
}

.booking-row span {
    font-size: 18px;
    color: var(--body-text-gray);
    margin-left: 10px;
}

.booking-row span:last-child {
    color: var(--primary-blue);
}

.booking-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid var(--input-text-color);
    margin-top: 10px;
    outline: none;
    box-sizing: border-box;
    color: #333;
    margin-bottom: 1rem;
}

.booking-input::placeholder {
    color: var(--body-text-gray);
}

.booking-row:last-child {
    /* border-top: 1px solid #ddd; */
    padding-top: 15px;
    margin-top: 10px;
}

.booking-row:last-child label {
    font-weight: bold;
    color: var(--text-color);
}

.total {
    color: var(--primary-blue);
}

.booking-row:last-child span {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-blue);
}

/* Select package */
.select-package {
    padding: 20px;
    border: 1px solid var(--border-gray);
    border-radius: 10px;
}

.package {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--body-text-gray);
    border-radius: 10px;
    cursor: pointer;
}

.package:hover {
    border-color: var(--primary-blue);
}

.package input {
    margin-right: 10px;
}

.select-package h2 {
    font-size: 16px;
    font-weight: bold;
}

.package h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.package p {
    margin: 2px 0;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-blue);
}

.package small:last-of-type {
    font-weight: bold;
    color: var(--body-text-gray);
    /* overflow: hidden; */
}

/* checkbox */
.package input[type="checkbox"],
.payment-options input[type="checkbox"] {
    width: 20px;
    height: 20px;
    padding: 10px;
    border: 2px solid var(--primary-blue);
    border-radius: 5px;
    appearance: none; /* Remove default checkbox appearance */
    cursor: pointer;
    position: relative;
}

/* Checked state */
.package input[type="checkbox"]:checked,
.payment-options input[type="checkbox"]:checked {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
}

/* Custom checkmark */
.package input[type="checkbox"]:checked::before,
.payment-options input[type="checkbox"]:checked::before {
    content: '✔'; /* Custom checkmark */
    color: white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Unchecked state (default) */
.package input[type="checkbox"]::before,
.payment-options input[type="checkbox"]::before {
    content: ''; /* Empty content for unchecked state */
}

/* Payment options */
.payment-options {
    text-align: start;
    margin-top: 20px;
}

.payment-options h2 {
    font-size: 16px;
    font-weight: bold;
}

/* Payment Options Footer */
.payment-options label {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.payment-options input[type="checkbox"] {
    margin-right: 10px;
    padding: 10px;
}

@media (max-width: 768px) {
    .booking-tour-container {
        padding: 0 8px 20px;
    }
    .booking-nav > ul > li {
        font-size: 6px;
    }

    .your-booking {
        width: 80%;
        margin: 1rem 0;
    }
}
