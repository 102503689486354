.logo {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 2%;
  top: 0;
}
.Signup-Container {
  background-color: #fefefecc;
  width: 480px;
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 40px;
  align-items: center; /* Center items horizontally */
  margin: 30px;
}

.Signup-title {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 20px;
  margin-top: -40px;
}

.Signup-title h2 {
  color: #019bbb;
  font-size: 24px;
}
.Signup-title p {
  font-size: 14px;
  margin-top: -15px;
}
.signup-form div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.signup-btn {
  background-color: #89a4aa;
  color: #fefefe;
  font-size: bold;
}
.signup-btn:hover {
  background-color: #019bbb;
}

@media (max-width: 768px) {
  .Signup-Container {
    width: 90%;
    padding: 40px;
    margin-top: 150px;
  }
  .Signup-title {
    margin-bottom: 20px;
  }
  .Signup-title h2 {
    font-size: 20px;
  }
  .Signup-title p {
    font-size: 12px;
  }
  .signup-form input {
    width: 100%;
  }
  .signup-form button {
    width: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 2%;
    right: 50%;
  }
}

@media (max-width: 480px) {
  .Signup-Container {
    padding: 30px;
    width: 80%;
    margin-top: 150px;
  }
  .Signup-title {
    margin-top: 20px;
  }
  .Signup-title h2 {
    font-size: 18px;
  }
  .Signup-title p {
    font-size: 10px;
  }
  .signup-form input {
    width: 100%;
  }
  .signup-form button {
    width: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 2%;
    right: 50%;
  }
}
