.add-travelers-container {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    flex-wrap: wrap;
}

.form-section h2 {
    font-size: 16px;
    color: var(--text-color);
}

.add-travelers-container h3 {
    font-size: 12px;
    color: var(--body-text-gray);
}

.add-travelers-container h3 a {
    color: var(--primary-blue);
}

.your-booking-section {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 18px;
    background-color: #f8f8f8;
}

.input-group input,
.input-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--input-border-color);
    border-radius: 12px;
    font-family: 'Poppins' sans-serif;
}

.input-group {
    display: flex;
    align-items: center;
}

.date-input-group input {
    flex: 1;
    position: relative;
}

.date-input-group svg {
    margin-left: 0px;
    color: var(--input-border-color);
    position: absolute;
    left: 500px;
}

@media (max-width: 769px) {
    .date-input-group svg {
        left: 450px;
    }
}
@media (max-width: 550px) {
    .date-input-group svg {
        left: 75%;
    }
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
    /* Adjusts space between checkbox and label */
}

.checkbox-group input[type="checkbox"] {
    width: 20px;
    height: 20px;
    appearance: none;
    border: 2px solid var(--primary-blue);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.checkbox-group input[type="checkbox"]:checked {
    background-color: var(--primary-blue);
    /* Fill background when checked */
}

.checkbox-group input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-group label {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #5a5a5a;
    /* Gray color similar to the image */
    cursor: pointer;
}

.social-media-container {
    text-align: center;
    overflow: hidden;
}

.social-media-container p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.social-buttons {
    max-width: 600px;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: nowrap;
}

.social-buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid;
    transition: all 0.3s ease;
}

/* LinkedIn Button */
.linkedin-btn {
    background-color: #fefefe;
    border-color: #0077b5;
    border: 2px solid;
    color: #0077b5;
}

.linkedin-btn:hover {
    background-color: #0077b5;
    color: #fff;
}

/* Facebook Button */
.facebook-btn {
    background-color: transparent;
    border-color: #4267b2;
    color: #4267b2;
}

.facebook-btn:hover {
    background-color: #4267b2;
    color: #fff;
}

/* Google Button */
.google-btn {
    background-color: transparent;
    border-color: #db4437;
    color: #db4437;
}

.google-btn:hover {
    background-color: #db4437;
    color: #fff;
}
@media(max-width: 500px){
    .social-buttons {
        flex-wrap: wrap;
    }
}
.your-booking-section h3 {
    margin-bottom: 20px;
}

.booking-details p {
    font-size: 16px;
    margin-bottom: 10px;
}

.edit-btn {
    background-color: transparent;
    border: 1px solid #00b5ad;
    color: #00b5ad;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}

.edit-btn:hover {
    background-color: #00b5ad;
    color: white;
}

.Acknowledgement-risk > h3 {
    font-size: 16px;
    color: var(--text-color);
}

.Acknowledgement-risk h3::after {
    content: "*";
    color: red;
}

.Acknowledgement-risk p {
    font-size: 16px;
    color: var(--body-text-gray);
}

.Acknowledgement-risk > .disableText {
    color: var(--input-text-color);
}

.Acknowledgement-risk .readMore {
    display: block;
    margin-top: 2px;
    color: var(--primary-blue);
    text-decoration: underline;
    border: none;
}

.add_traverler-conti {
    display: flex;
    justify-content: space-between;
    padding: 0px;
}
