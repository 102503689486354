@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

:root {
  --primary-blue: #019bbb;
  --text-color: #1e1e1e;
  --form-bg-color: #fefefe;
  --input-text-color: #ababab;
  --body-text-gray: #6d6d6d;
  --button-text-white: #fefefe;
  --button-disabled: #89a4aa;
  --button-font-size: 18px;
  --heading-font-size: 24px;
  --body-font-size: 16px;
  --button-radius: 18px;
  --button-bg-blue: #019bbb;
  --input-bg-color: #f9f8f8;
  --input-border-color: #35353580;
}

body {
  font-family: 'Poppins', sans-serif; /* Apply Poppins to all text */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}
