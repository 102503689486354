.review-booking-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
    flex-wrap: wrap;
}

.your-trip {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.review-booking-container input:hover {
    border: 1px solid var(--primary-blue);
}

.your-trip h2 {
    font-size: 16px;
    color: var(--text-color);
    font-weight: bold;
    margin-top: 1rem;
}

.trip-details,
.traveler-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid var(--primary-blue);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    /* Adding shadow */
}

.trip-details h3 {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
}

.trip-details > div {
    font-size: 16px;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
}

.trip-details > div div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 15px;
}

.trip-details > div div a {
    color: var(--body-text-gray);
    font-size: 16px;
    text-decoration: none;
}

.trip-details > div span {
    color: var(--primary-blue);
    font-size: 22px;
}

.traveler-details > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 16px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.traveler-details > div div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 15px;
    border-bottom: 1px solid var(--body-text-gray);
}

.traveler-details > div a {
    color: var(--primary-blue);
    font-size: 24px;
    /* Adjusted size */
    display: block;
    margin-bottom: 5px;
}

.traveler-details > div span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.traveler-details > div div span {
    color: var(--body-text-gray);
}

/* payment options */
.payment-options {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--primary-blue);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 25px;
}

.payment-options > label {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;
}

.payment-options input[type="checkbox"], .terms input[type="checkbox"] {
    width: 20px;
    height: 20px;
    appearance: none;
    border: 2px solid var(--primary-blue);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.payment-options input[type="checkbox"]:checked, .terms input[type="checkbox"]:checked {
    background-color: var(--primary-blue);
    /* Fill background when checked */
}

.payment-options input[type="checkbox"]:checked::before, .terms input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* payment options style end here */

.payment-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.payment-form input {
    padding: 20px 20px;
    margin-bottom: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 12px;
}

.payment-form .row input:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.payment-form .row input:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.payment-form,
.billing-address {
    margin-bottom: 20px;
}

/* Billing style */
.billing-address {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.billing-address input,
select {
    padding: 20px 20px;
    margin-bottom: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 12px;
}

.billing-address input:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.billing-address input:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}


.terms span {
    color: var(--body-text-gray);
}

.terms span a {
    text-decoration: none;
    color: var(--primary-blue);
}

.payment-form .row,
.billing-address .row {
    display: flex;
    justify-content: space-between;
}

.payment-form .row input,
.billing-address .row input {
    width: 48%;
}

@media (max-width: 768px) {
    .your-trip {
        width: 100%;
    }

    .trip-details h3 {
        font-size: 16px;
    }
}