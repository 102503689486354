@tailwind base;
@tailwind components;
@tailwind utilities;

#about-us-page .hero-section {
    background-image: url("../images/about-us-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 85vh;
}
#about-us-page .header-section {
    height: 50vh;
    display: flex;
    align-items: flex-end;
}
#about-us-page .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#about-us-page .header .heading{
    font-size: 36px;
    color: #FEFEFE;
    font-weight: bold;
}
#about-us-page .header .para{
    width: 60%;
    color: #FEFEFE;
    font-size: 20px;
}

/* Seize The Day */
#about-us-page .seize-the-day{
    padding: 40px 40px;
    margin: 0px 30px;
}
#about-us-page .seize-the-day .para-1{
    font-size: 16px;
    color: #1E1E1E;
}
#about-us-page .seize-the-day .para-1 span{
    font-size: 32px;
    color: #019BBB;
}
#about-us-page .seize-the-day .para-2{
    font-size: 16px;
    color: #1E1E1E;
    margin-top: 32px;
}

/* Vision & Mission */

#about-us-page .vision-mission {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-bottom: 10px;
}
#about-us-page .vision-mission .vision{
    border-radius: 18px;
    padding: 20px 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.19);
}
#about-us-page .vision-mission .mission{
    border-radius: 18px;
    padding: 20px 20px;
    margin-left: 24px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 768px) {
    #about-us-page .vision-mission .vision{
        margin: 0px 24px 0px 24px;
    }
    #about-us-page .vision-mission .mission{
        margin: 14px 24px 0px 24px;
    }
    #about-us-page .meet-our-team{
        width: 100%;
    }
}