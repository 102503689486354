main {
  height: auto;
  width: 100%;
}

.tour-details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 50px;
  margin-top: 50px;
}

.tour-details-text {
  width: 50%;
}

.tour-details-text h1 {
  font-size: 24px;
}
.tour-details-text p {
  font-size: 16px;
  color: #019bbb;
}

.view-section {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 70px;
}

.sort-by-view {
  display: flex;
  flex-direction: row;
  justify-content: middle;
  align-items: center;
  gap: 10px;
}
.sort-by-view select {
  width: 140px;
  height: 52px;
  border-radius: 14px;
  border: 1px solid #c9c9c9;
  background-color: #fefefe;
  padding: 16px;
}
.sort-by-view select:focus {
  border-color: #019bbb;
  box-shadow: 0 0 5px rgba(34, 158, 215, 0.5);
}

.select-by-view select::after {
  color: #019bbb;
}

.sort-by-view p {
  font-size: 14px;
  color: #6d6d6d;
}

.image-view-map-view-btn {
  display: flex;
  flex-direction: row;
  justify-content: middle;
  align-items: center;
  gap: 10px;
}
.image-view-map-view-btn button {
  width: 100px;
  height: 52px;
  border-radius: 14px;
  border: 1px solid #c9c9c9;
  background-color: #fefefe;
}

.image-active-btn {
  background-color: #019bbb !important;
  color: #fefefe !important;
}

.map-active-btn {
  background-color: #019bbb !important;
  color: #fefefe !important;
}

.tour-selection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 70px;
  height: 120px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.sliders-container {
  width: 300px;
  margin-top: 20px;
}

.budget-slider,
.duration-slider {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

label {
  font-weight: 300;
  font-size: 16px;
  margin-right: 10px;
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #019bbb 0%,
    #019bbb var(--value),
    #ddd var(--value),
    #ddd 100%
  );
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
}

input[type='range']:hover {
  opacity: 1;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #019bbb;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  margin-top: -2px;
}

input[type='range']::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #019bbb;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

input[type='range']::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 5px;
  background: transparent;
}

input[type='range']::-moz-range-track {
  height: 4px;
  border-radius: 5px;
  background: transparent;
}

#budget-value,
#duration-value {
  font-size: 14px;
  margin-left: 10px;
  color: #019bbb;
  font-weight: bold;
}

.calenders {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  border-radius: 24px;
  margin-top: 20px;
  background-color: #fefefe;
  border: 1px solid #c9c9c9;
  height: 50px;
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(99, 99, 99, 0.2);
}

.calenders i {
  display: inline-flex;
  color: #6d6d6d;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer; /* Change cursor to pointer */
}

.calenders p {
  margin: 10px;
  font-size: 14px;
  color: #6d6d6d; /* Make text color consistent */
  margin: 0;
  display: inline-flex;
  cursor: pointer; /* Change cursor to pointer */
}

.calenders hr {
  width: 1px;
  height: 30px;
  background-color: #8d96a6a8;
}

.departure-before,
.departure-after {
  display: flex;
  flex-direction: row;
  margin: 15px;
  position: relative; /* Positioning for the date picker */
}

/* Styles for the date picker */
/* General styles for the calendar */
.react-datepicker {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 0;
  width: 280px; /* Set a width to control the calendar size */
}

.react-datepicker__header {
  background-color: #019bbb;
  color: white;
  border-radius: 8px 8px 0 0;
  padding: 10px;
  text-align: center;
}

/* Weekday names */
.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 equal columns for days */
  padding: 5px;
  background-color: #f0f0f0;
}

.react-datepicker__day-name {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #6d6d6d;
}

/* Grid for dates */
.react-datepicker__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 5px;
}

.react-datepicker__day {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.react-datepicker__day:hover {
  background-color: #eaeaea;
}

.react-datepicker__day--selected {
  background-color: #019bbb;
  color: white;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
  background-color: #019bbb;
  color: white;
  border-radius: 50%;
}
.react-datepicker__navigation {
  top: 10px; /* Position from the top of the calendar */
  width: 34px; /* Width of the button */
  height: 34px; /* Height of the button */
  border: none;
  background-color: transparent;
  color: white; /* Change to your preferred color */
  cursor: pointer;
  outline: none;
}

.custom-header {
  display: flex;
  justify-content: center; /* Center content */
  align-items: center;
  background-color: #019bbb; /* Header background */
  padding: 10px;
  border-radius: 8px 8px 0 0; /* Rounded corners */
}

.custom-header button {
  background: none;
  border: none;
  color: white; /* Button color */
  cursor: pointer;
  font-size: 18px; /* Adjust the size */
}

/* Style for the month name */
.month-name {
  color: white; /* Month name color */
  font-weight: bold; /* Bold month name */
  margin: 0 10px; /* Space around month name */
}

.custom-header button:hover {
  color: #02a4c2;
}
.filter-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  gap: 10px;
}

.tours-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 50px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
}
.filter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  width: 400px;
  padding: 10px;
}

.country-filter {
  margin: 30px;
  margin-left: 80px;
}

.country-filter p {
  font-size: 18px;
  color: #019bbb;
}

.filter-check-box {
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertically center align */
  gap: 5px; /* Space between checkbox and label */
  height: 40px; /* Fixed height for consistent alignment */
  width: 100%; /* Make each checkbox row full width */
}

.filter-check-box input[type='checkbox'] {
  width: 25px; /* Consistent checkbox width */
  height: 20px; /* Consistent checkbox height */
  cursor: pointer; /* Pointer cursor on hover */
  box-shadow: 0px 2px 2px rgba(99, 99, 99, 0.2); /* Optional shadow */
}

.filter-check-box input[type='checkbox']:checked {
  border-color: #019bbb;
}
.amazon-program-filter {
  margin-left: 80px;
}

.ecuador-filter {
  margin-left: 80px;
}
.flow-design {
  margin-left: 80px;
}
.physical-grading {
  margin-left: 80px;
}

.filter-check-box label {
  display: inline-flex; /* Use inline-flex for better alignment */
  align-items: center; /* Vertically center text */
  font-size: 16px; /* Set a consistent font size */
  margin-left: 5px; /* Slight space between checkbox and text */
  color: #6d6d6d;
}
.explore-section-cards-tours {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create 2 equal columns */
  gap: 50px; /* Space between the cards */
  margin-top: 20px; /* Margin at the top */
  margin: 0 auto; /* Center the container */
  height: auto;
}

.explore-card-tours {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 600px; /* Set a fixed height for cards */
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.explore-card-tours img {
  width: 90%;
  height: 300px;
  border-radius: 24px;
  margin-top: 20px;
}
.explore-card-title {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.explore-card-title h1 {
  color: #019bbb;
  font-size: 16px;
  font-weight: 600;
}
.explore-card-title p {
  font-size: 12px;
  font-weight: 400;
  color: #6d6d6d;
  margin-top: -10px;
}
.explore-card-description {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

.explore-card-description p {
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-top: -10px;
  word-wrap: break-word;
}

.explore-card-tours hr {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.explore-card-footer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.explore-card-footer-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.explore-card-footer-text h1 {
  color: #019bbb;
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}
.explore-card-footer-text p {
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
  margin-top: 10px;
  margin-left: 10px;
}
.explore-card-footer button {
  background-color: #019bbb;
  color: white;
  font-size: 14px;
  font-weight: medium;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  height: 50px;
  cursor: pointer;
}

.image-view-btn-small .icon,
.map-view-btn .icon {
  display: none;
}

.filtered-icons {
  display: none;
}

.image-view-map-view-btn-small {
  display: flex;
  flex-direction: row;
  justify-content: middle;
  align-items: center;
  gap: 10px;
}
.filter-icon {
  display: inline;
}
.image-view-map-view-btn-small button {
  width: 50px;
  height: 52px;
  border-radius: 14px;
  border: 1px solid #c9c9c9;
  background-color: #fefefe;
}

.image-view-btn {
  display: inline;
}
.map-view-btn {
  display: inline;
}
.applied-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.filter-box {
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #02a4c2;
}

.remove-icon {
  margin-left: 8px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #019bbb;
  width: 10px;
  height: 10px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .tour-details {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center align items */
  }

  .tour-details-text {
    width: 90%; /* Take more width on small screens */
    text-align: center; /* Center text */
  }

  .view-section {
    flex-direction: column; /* Stack view section items */
    align-items: center; /* Center align */
  }

  .sort-by-view {
    flex-direction: column; /* Stack sort options */
    align-items: center; /* Center align */
  }

  .tour-selection {
    flex-direction: column; /* Stack selections vertically */
    align-items: center; /* Center align */
  }

  .sliders-container {
    width: 60%;
  }

  .budget-slider,
  .duration-slider {
    flex-direction: column; /* Stack labels and sliders */
  }

  .departures-before,
  .departures-after {
    flex-direction: column; /* Stack date pickers */
    align-items: center; /* Center align */
  }

  .calenders {
    flex-direction: row; /* Stack calendar items */
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .tours-section {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
  }
  .tour-selection {
    height: auto;
  }

  /* Adjust font sizes */
  .tour-details-text h1 {
    font-size: 20px; /* Smaller heading */
  }

  .tour-details-text p {
    font-size: 14px; /* Smaller paragraph */
  }

  .sort-by-view select {
    width: 100%; /* Full width for dropdown */
  }

  .image-view-map-view-btn {
    flex-direction: column; /* Stack buttons */
    align-items: center; /* Center align */
  }

  .image-view-map-view-btn button {
    width: 100%; /* Full width for buttons */
    margin-bottom: 10px; /* Spacing between buttons */
  }

  .custom-header button {
    font-size: 16px; /* Smaller button text */
  }
  .filter-wrapper {
    width: 90%;
    margin-left: 20px;
  }

  #budget-value,
  #duration-value {
    font-size: 16px;
  }
  .explore-section-cards-tours {
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    width: 90%;
    margin: 40px;
  }
  .explore-card-tours {
    width: 100%;
    height: auto;
  }
  .explore-card-footer {
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .tour-details {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center align items */
  }

  .tour-details-text {
    width: 90%; /* Take more width on small screens */
    text-align: center; /* Center text */
  }

  .view-section {
    flex-direction: column; /* Stack view section items */
    align-items: center; /* Center align */
  }

  .sort-by-view {
    flex-direction: column; /* Stack sort options */
    align-items: center; /* Center align */
  }

  .tour-selection {
    flex-direction: column; /* Stack selections vertically */
    align-items: center; /* Center align */
  }

  .sliders-container {
    width: 70%;
  }

  .budget-slider,
  .duration-slider {
    flex-direction: column; /* Stack labels and sliders */
  }

  .departures-before,
  .departures-after {
    flex-direction: column; /* Stack date pickers */
    align-items: center; /* Center align */
  }

  .calenders {
    flex-direction: row; /* Stack calendar items */
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .tours-section {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
  }
  .tour-selection {
    height: auto;
  }

  /* Adjust font sizes */
  .tour-details-text h1 {
    font-size: 20px; /* Smaller heading */
  }

  .tour-details-text p {
    font-size: 14px; /* Smaller paragraph */
  }

  .sort-by-view select {
    width: 100%; /* Full width for dropdown */
  }

  .image-view-map-view-btn {
    flex-direction: column; /* Stack buttons */
    align-items: center; /* Center align */
  }

  .image-view-map-view-btn button {
    width: 100%; /* Full width for buttons */
    margin-bottom: 10px; /* Spacing between buttons */
  }

  .custom-header button {
    font-size: 16px; /* Smaller button text */
  }
  .filter-wrapper {
    width: 90%;
    margin-left: 20px;
  }

  #budget-value,
  #duration-value {
    font-size: 12px; /* Smaller value text */
  }
  .explore-section-cards-tours {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    width: 90%;
    margin: 40px;
  }
  .explore-card-tours {
    width: 100%;
    height: auto;
  }
  .explore-card-footer {
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .tour-details-text h1 {
    font-size: 18px; /* Even smaller heading */
  }

  .tour-details-text p {
    font-size: 12px; /* Smaller paragraph */
  }
  .sort-by-view {
    display: none;
  }
  .sort-by-view select {
    width: 100%; /* Ensure dropdowns are full width */
  }

  .image-view-map-view-btn {
    flex-direction: row; /* Stack buttons */
    align-items: center; /* Center align */
    display: none;
  }

  .image-view-map-view-btn button {
    width: 100%; /* Full width buttons */
  }

  .budget-slider,
  .duration-slider {
    flex-direction: column; /* Stack labels and sliders */
    align-items: flex-start; /* Align items to start */
  }

  #budget-value,
  #duration-value {
    font-size: 10px; /* Smaller value text */
  }

  .calenders {
    flex-direction: row; /* Stack calendar items */
    align-items: center;
    justify-content: center; /* Center align */
  }
  .tour-details {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .view-section {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .image-view-btn .icon,
  .map-view-btn .icon {
    display: inline;
    font-size: 30px !important;
  }

  .image-view-btn .text,
  .map-view-btn .text {
    display: none;
  }
  .tour-selection {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
    display: none;
  }
  .filtered-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .filter-wrapper {
    width: 90%;
    margin: auto !important;
    display: none;
  }
  .filter-visible.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .filter-wrapper {
      display: flex;
    }
    .sort-by-view {
      display: flex;
    }
    .applied-filters {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
    }
    .filter-box {
    }
  }
  .sliders-container {
    width: 70%;
  }
  .calenders {
    width: 80%;
  }

  .explore-section-cards-tours {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    width: 80%;
    margin: 40px;
  }
  .explore-card-tours {
    width: 100%;
    height: auto;
  }
  .explore-card-footer {
    margin-bottom: 8px;
  }
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  padding: 40px;
}

.pagination-controls button {
  color: black;
  border: none;
  border-radius: 100px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem; /* Responsive font size */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.pagination-controls button:hover {
  background-color: #019bbb;
  color: white;
}
.pagination-controls button.active {
  background-color: #019bbb;
  color: white;
}
.pagination-count {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.next,
.prev {
  background-color: #019bbb;
  color: white !important;
  border: none;
  border-radius: 10px !important;
  padding: 0px 20px 0px 10px !important;
  cursor: pointer;
  width: 5px;
  height: 50px;
  text-align: center;
  font-size: x-large !important;
}

.next {
  margin-left: 20px;
}

.prev {
  margin-right: 20px;
}
.filter-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  border-radius: 14px;
  border: 1px solid black;
  width: 100px;
  height: 50px;
  gap: 10px;
}
.filter-icon button {
  border: none;
  border-radius: 14px;
  width: 100px;
  height: 50px;
  cursor: pointer;
  background-color: white;
}
@media (max-width: 480px) {
  .pagination-controls {
    padding: 0px;
    width: 100%;
    margin: 0px;
  }
  .pagination-count {
    display: none;
  }
}
