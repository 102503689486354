.top-section {
  /* background-image: url('../images/joao-marta-sanfins-SnyAUL9pWrA-unsplash.jpg'); */
  background-size: cover; /* Cover the entire hero area */
  background-position: center; /* Center the background image */
  height: auto; /* Set a minimum height for the hero section */
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url('../images/joao-marta-sanfins-SnyAUL9pWrA-unsplash.jpg') lightgray -0.023px -742.504px /
      100% 302.362% no-repeat;
  flex-shrink: 0;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 40px;
}

.hero-text {
  margin: 90px;
  text-align: center;
  align-self: center;
  /* margin-left: 160px; */
}
.search-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  /* z-index: 100; */
}

.search-bar {
  position: relative;
  width: 800px;
}

.search-bar input {
  background: rgba(254, 254, 254, 0.1);
  width: 100%;
  padding: 30px 50px;
  border-radius: 40px;
  border: 1px solid #019bbb;
  font-size: 16px;
  color: #ccc;
}

.search-bar input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(113, 215, 199, 0.496);
  backdrop-filter: blur(6.25px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.search-text-container {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #019bbb; /* Background color for the text and icon */
  padding: 10px 20px; /* Padding for spacing */
  border-radius: 30px; /* Rounded corners */
  display: flex; /* Flex to align text and icon */
  align-items: center; /* Center items vertically */
  box-shadow: 0 0 5px rgba(113, 215, 199, 0.496);
}

.search-text {
  color: white; /* Color for the search text */
  margin-right: 5px; /* Space between text and icon */
}

.search-icon {
  font-size: 24px; /* Size of the search icon */
  color: #ccc; /* Color of the search icon */

  cursor: pointer;
}
.blur-background {
  filter: blur(5px); /* Apply blur effect */
}

.search-container,
.search-bar,
.search-bar input,
.search-text-container {
  filter: none !important;
}
.search-text-container:hover {
  background-color: #2190a6; /* Change background color on hover */
}

/* Styling for the recommendations dropdown */
.recommendations-dropdown {
  position: absolute; /* Keep it relative to the input field */
  width: 80%; /* Make it as wide as the input field */
  background-color: white; /* Background color for the dropdown */
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */ /* Border color matching input field */
  border-top: none; /* Remove the border on top */
  z-index: 10; /* Ensure it's above other elements */
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.recommendations-dropdown li {
  list-style-type: none;
  padding: 10px;
  color: #333;
  cursor: pointer;
}

.recommendation-item {
  padding: 10px; /* Padding for individual items */
  color: #333; /* Text color */
  cursor: pointer; /* Pointer cursor for interactivity */
}

.recommendation-item:hover {
  background-color: #019bbb; /* Highlight on hover */
  color: white; /* Change text color on hover */
}

.hero-text h1 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 41.333px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline;
}
.hero-text strong {
  color: #fff;
  font-family: Poppins;
  font-size: 41.333px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;
}

.hero-text p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #fefefe;
}

.middle-section {
  height: auto;
  display: flex;
  flex-direction: row;
}

.middle-text-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 80px;
}
.middle-text-section h1 {
  font-weight: 600;
  font-size: 32px;
  color: black;
  width: 500px;
}
.middle-text-section h3 {
  font-weight: 400;
  font-size: 20px;
  color: #019bbb;
  width: 500px;
  margin-top: -20px;
}

.middle-text-section p {
  font-weight: 400;
  font-size: 16px;
  text-wrap: wrap;
  color: #6d6d6d;
}

.middle-section-paragraph {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}
.img-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.img-section img {
  width: 70%;
  height: 500px;
  border-radius: 30px;
}

.middle-section-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.middle-section-btn button {
  background-color: #019bbb;
  color: white;
  font-size: 18px;
  font-weight: medium;
  padding: 15px 30px;
  border-radius: 30px;
  border: none;
  height: 80px;
  cursor: pointer;
}

/* General styles remain the same */

@media (max-width: 1100px) {
  .top-section {
    height: 40vh !important;
  }
  nav {
    flex-direction: row;
    height: auto;
  }
  .nav-titles,
  .nav-icons {
    gap: 20px;
  }

  .hero {
    display: flex;
    flex-direction: column; /* Keep column layout */
    align-items: center; /* Center items */
    justify-content: center; /* Center items */
    justify-items: center;
    padding: 20px;
    text-align: center; /* Center text */
  }

  .search-bar input {
    padding: 20px; /* Adjust padding */
    font-size: 14px; /* Smaller font size */
    width: 70%;
  }

  .search-text-container {
    width: 10%;
    padding: 10px;
    height: 20px;
    margin-right: 110px;
  }
  .search-text-container span {
    font-size: 16px;
  }

  .middle-section {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center items */
    justify-content: center;
    justify-items: center;
  }

  .middle-text-section,
  .img-section {
    margin: 20px 0; /* Reduce margin */
  }

  .middle-text-section h1,
  .middle-text-section h3,
  .middle-text-section p {
    width: 100%; /* Full width for text */
    text-align: center; /* Center text */
  }

  .img-section img {
    width: 100%; /* Full width on small screens */
    height: auto; /* Maintain aspect ratio */
  }

  .middle-section-btn {
    margin-top: 30px; /* Adjust button margin */
  }

  .middle-section-btn button {
    padding: 10px 20px; /* Adjust button padding */
    font-size: 16px; /* Smaller button font size */
  }
}

.explore-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #effaff;
  height: 800px;
  width: 100%;
}

.explore-text-section {
  width: 100%;
  text-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  gap: 700px;
}

.explore-text-section h1 {
  font-weight: 600;
  font-size: 28px;
  color: black;
  width: 600px;
}
.explore-text-section a {
  font-weight: 400;
  font-size: 16px;
  color: #019bbb;
  text-decoration: none;
  cursor: pointer;
}

.explore-section-cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.explore-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 450px;
  height: 600px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.explore-card img {
  width: 90%;
  height: 300px;
  border-radius: 24px;
  margin-top: 20px;
}
.explore-card-title {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.explore-card-title h1 {
  color: #019bbb;
  font-size: 16px;
  font-weight: 600;
}
.explore-card-title p {
  font-size: 12px;
  font-weight: 400;
  color: #6d6d6d;
  margin-top: -10px;
}
.explore-card-description {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  height: 70px;
}

.explore-card-description p {
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-top: -10px;
  word-wrap: break-word;
}

.explore-card hr {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.explore-card-footer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.explore-card-footer-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.explore-card-footer-text h1 {
  color: #019bbb;
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}
.explore-card-footer-text p {
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
  margin-top: 10px;
  margin-left: 10px;
}
.explore-card-footer button {
  background-color: #019bbb;
  color: white;
  font-size: 14px;
  font-weight: medium;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  height: 50px;
  cursor: pointer;
}

.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: white;
  height: auto;
  width: 100%;
}
.bottom-section-titles {
  margin-left: 60px;
  margin-top: 5px;
}

.bottom-section-titles h1 {
  font-weight: 800px;
  font-size: 26px;
  color: black;
  width: 600px;
}
.bottom-section-titles h3 {
  font-weight: 400;
  font-size: 18px;
  color: #019bbb;
  width: 600px;
  margin-top: -15px;
}

.bottom-section-description p {
  font-weight: 400;
  font-size: 16px;
  text-wrap: wrap;
  color: #6d6d6d;
  margin-left: 60px;
  margin-top: 20px;
  width: 90%;
}
.bottom-section-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
}
.bottom-section-card {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f5f5f5;
  width: 450px;
  height: 300px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.card-1::before,
.card-2::before,
.card-3::before,
.card-4::before,
.card-5::before,
.card-6::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 250px;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.8;
  z-index: 0;
  margin-bottom: -30px;
}

.card-1::before {
  background-image: url('../images/9025436-removebg-preview.png');
}

.card-2::before {
  background-image: url('../images/9227663-removebg-preview.png');
}

.card-3::before {
  background-image: url('../images/10059520-removebg-preview.png');
}

.card-4::before {
  background-image: url('../images/4038091-removebg-preview.png');
}

.card-5::before {
  background-image: url('../images/5647548-removebg-preview.png');
}

.card-6::before {
  background-image: url('../images/9035545-removebg-preview.png');
}
.bottom-card-text {
  width: 90%;
  margin: 30px;
  z-index: 2;
}
.bottom-card-text p {
  font-size: 14px;
  font-weight: 400;
  color: #6d6d6d;
  width: 50%;
}

/* General styles remain the same */
@media (max-width: 1200px) {
  .top-section {
    height: auto !important;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .search-bar {
    width: 100%;
  }
  .search-bar input {
    width: 80%;
  }
  .search-text-container {
    width: 80px;
    padding: 10px;
    height: 20px;
  }
  .middle-section {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
  }
  .img-section img {
    width: 100%;
  }
  .middle-section-headings {
    align-self: center;
  }

  .middle-text-section,
  .img-section {
    width: 40%;
    margin: 20px 0;
  }

  .middle-section-btn {
    margin-top: 30px;
    align-self: center;
    border-radius: 48px;
    background: #019bbb;
    width: 400px;
    height: 50px;
  }
  .middle-section-btn button {
    padding: 10px 20px;
    font-size: 16px;
    width: 400px;
    height: 50px;
  }
  .explore-section {
    height: auto;
  }
  .explore-text-section {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .explore-section-cards {
    flex-direction: column;
    align-items: center;
  }

  .explore-card {
    width: 60%;
    margin-bottom: 20px;
  }

  .bottom-section {
    height: auto;
  }

  .bottom-section-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .bottom-section-card {
    width: 80%;
    margin-bottom: 20px;
  }
}

@media (max-width: 828px) {
  .top-section {
    height: 50vh;
  }
  .hero-text {
    width: 80% !important;
  }
  .search-bar input {
    width: 70%;
  }
  .middle-text-section h1 {
    font-size: 24px;
  }

  .middle-text-section h3 {
    font-size: 16px;
  }

  .img-section img {
    width: 100%;
    height: auto;
  }

  .explore-section {
    height: auto;
  }

  .explore-text-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .explore-text-section h1 {
    font-size: 24px;
    width: 400px;
  }

  .explore-card {
    width: 70%;
  }

  .bottom-section-description p {
    font-size: 14px;
  }

  .bottom-section-card {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .top-section {
    width: 100%;
    background-size: auto;
    height: 489px !important;
  }
  .hero-text {
    width: 100% !important;
    margin: 0;
    padding: 0;
    align-self: middle !important;
    text-align: center;
  }

  .middle-section-btn {
    width: 300px;
  }
  .middle-section-btn button {
    width: 300px
  }

  .hero-text h1 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .hero-text strong {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .hero-text p {
    font-size: 14px;
    font-weight: 400 !important;
    color: #cacaca;
    line-height: normal;
    text-align: center;
  }
  .search-bar input {
    padding: 15px; /* Adjust padding */
    font-size: 12px; /* Smaller font size */
    width: 100%;
  }
  .search-text-container {
    padding: 5px 10px;
    border-radius: 20px;
    width: 60px;
    margin-right: -10px;
  }
  .search-text {
    margin-right: 3px; /* Space between text and icon */
    font-size: small !important;
  }
  .search-icon {
    font-size: 16px; /* Size of the search icon */
  }
  .middle-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .middle-text-section {
    width: 80%;
    margin: 20px 0;
  }
  .img-section {
    display: none;
  }
  .middle-section-headings {
    padding: auto;
    margin: auto;
  }
  .middle-section-paragraph {
    padding: auto;
    margin: auto;
  }
  .explore-text-section h1 {
    font-size: 20px;
    width: full;
  }

  .middle-section-headings {
    margin-top: 20px;
  }
  .middle-text-section h1 {
    font-size: 20px;
    width: 200px;
  }

  .middle-text-section h3 {
    font-size: 14px;
  }
  .explore-section {
    height: auto;
    width: 100%;
  }

  .explore-text-section h1 {
    width: 70%;
  }
  .explore-card-wapper {
    width: full;
  }
  .explore-card {
    width: 80%;
    height: auto;
  }
  .explore-card-footer-text h1 {
    font-size: 20px !important;
  }

  .bottom-section-titles {
    margin-left: 20px;
  }
  .bottom-section-titles h1 {
    font-size: 20px;
    width: 300px;
  }
  .bottom-section-titles h3 {
    font-size: 14px;
    width: 300px;
  }
  .bottom-section-description p {
    margin-left: 20px;
    font-size: 12px;
  }
  .bottom-section-card {
    width: 80%;
  }

  .card-1::before,
  .card-2::before,
  .card-3::before,
  .card-4::before,
  .card-5::before,
  .card-6::before {
    width: 160px;
    height: 200px;
    opacity: 1;
  }
}
