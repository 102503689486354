#privacy-policy-page .hero-section {
    background-image: url("../images/about-us-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 85vh;
}
#privacy-policy-page .header-section {
    height: 50vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
#privacy-policy-page .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#privacy-policy-page .header .heading{
    font-size: 36px;
    color: #FEFEFE;
    font-weight: bold;
}
#privacy-policy-page .header .para{
    width: 60%;
    color: #FEFEFE;
    font-size: 20px;
    margin: 0px;
}