.tour-add-ons-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    flex-wrap: wrap;
}

.left-side{
    width: 50%;
}
.accordion-container{
    /* width: 50%;
    max-width: 700px; */
    margin-top: 2rem;
    border: 1px solid #ddd;
    border-radius: var(--button-radius);
    overflow: hidden;
    background-color: #fff;
}

.accordion-day {
    border-bottom: 1px solid #ddd;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
}

.accordion-header:hover {
    background-color: #e0f7fa;
}

.accordion-header h4{
    margin: 0;
    color: var(--primary-blue);
}
.accordion-header time {
    color: var(--body-text-gray);
}
.accordion-header .icon {
    font-size: 2rem;
    color: var(--body-text-gray);
}

.accordion-content {
    padding: 15px;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.addon {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #e0f7fa;
    padding: 1rem;
}

.addon-header, .addon-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-content h5{
    font-size: 1.2rem;
    margin: 1rem 0 0;
}

.addon-header p, .addon > p{
    color: var(--body-text-gray);
}

.add-remove-btn {
    align-self: flex-start;
    padding: .5rem 1rem;
    background-color: var(--form-bg-color);
    border: 2px solid var(--primary-blue);
    color: var(--primary-blue);
    cursor: pointer;
    border-radius: var(--button-radius);
    transition: background-color 0.3s;
}

.add-remove-btn:hover {
    background-color: #0097a7;
    color: var(--form-bg-color);
}

.price {
    font-weight: bold;
    color: #333;
}
.price span {
    color: var(--primary-blue);
}

.attendee-selection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #e0f7fa;
    padding: 1rem;
}

.attendee-selection input {
    margin-right: 10px;
}

.continue-btn {
    width: 100%;
    padding: 15px;
    background-color: #00bcd4;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0 0 10px 10px;
}

.continue-btn:hover {
    background-color: #0097a7;
}

@media (max-width: 1024px) {
    .left-side {
        width: 100%;
    }
    
}
