/* @tailwind base;
@tailwind components;
@tailwind utilities; */

#get-in-touch-page .hero-section {
    background-image: url("../images/about-us-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 85vh;
}
#get-in-touch-page .header-section {
    height: 50vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
#get-in-touch-page .header .heading{
    font-size: 36px;
    color: #FEFEFE;
    font-weight: bold;
}