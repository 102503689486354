.step-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--button-bg-blue);
    padding: 15px 20px;
    border-radius: var(--button-radius);
    color: var(--button-text-white);
}

.step-item {
    cursor: pointer;
    padding: 10px 20px;
    font-size: var(--body-font-size);
    white-space: nowrap;
}

.step-item.active {
    font-weight: bold;
    text-decoration: underline;
}

.step-item a {
    color: var(--button-text-white);
    text-decoration: none;
}

.step-arrow {
    color: var(--button-text-white);
    padding: 0 10px;
    font-size: var(--body-font-size);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .step-navigation {
        padding: 8px;
    }
}