@tailwind base;
@tailwind components;
@tailwind utilities;

#reviews {
  background-image: url('../images/about-us-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 80vh;
}
.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

@media screen and (max-width: 768px) {
  #card {
    height: auto;
  }
  #card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
  }
}
@media screen and (max-width: 1300px) {
  #card {
    height: 250px;
    padding: 5px;
  }
}
@media screen and (max-width: 1024px) {
  #card {
    height: 400px;
  }
}
