nav {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 20px; /* Optional padding for better spacing */
}

.nav-titles {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.nav-icons.open {
  display: none;
}

.navbar.home {
  color: white; /* or any color you want for the home page */
}
.navbar.other {
  color: black; /* or any color you want for other pages */
}
.nav-icons.other svg {
  color: black !important;
}

.nav-titles select {
  background-color: transparent;
  border: none;
  font-family: Poppins, sans-serif;
  width: 120px;
  font-size: 16px;
  text-align: center;
  align-items: center;

  cursor: pointer;
  margin-top: 12px;
  color: white;
}

#dropdown {
  width: 140px;
}
.nav-titles label {
  font-family: Poppins, sans-serif;
  text-align: center;
}
.nav-titles option {
  color: #019bbb;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}
.nav-icons {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 50px;
}
/* .nav-icons a {
  color: #019bbb;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
} */

/* Hamburger styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  height: 3px;
  width: 25px;
  background-color: #ababab;
  margin: 2px 0;
}

/* Media Queries */
@media (max-width: 1200px) {
  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  nav img {
    width: 70px;
    margin-top: 15px;
  }

  .nav-titles {
    display: none; /* Hide titles by default on small screens */
    flex-direction: column; /* Stack titles vertically */
    position: absolute; /* Position the menu */
    top: 90px; /* Position it below the navbar */
    justify-content: center;
    align-items: center;
    left: 0;
    background-color: #effaff; /* Background color for dropdown */
    width: 100%; /* Full width */
    gap: 20px; /* Spacing between items */
    padding: 5px; /* Padding for dropdown */
    border-radius: 10px;
    border: 1px solid gray;
    transform: translateY(-10px); /* Move up slightly for entrance effect */
    transition: opacity 0.3s ease, transform 0.3s ease;
    color: black;
    height: auto;
    z-index: 100;
  }
  .nav-titles select {
    color: black;
    padding: 0px !important;
    margin: 0px 0px;
  }

  .nav-icons a {
    font-size: small !important;
  }
  .nav-titles.open {
    display: flex; /* Show menu when open */
  }

  .nav-icons.open {
    display: flex;
  }
  .nav-icons {
    display: none;
  }

  .hamburger {
    display: flex; /* Show hamburger icon */
    /* font-size: small;
    background-color: #f8f8f888;
    border-radius: 6px;
    padding: 6px; */
  }
  .cross span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: #ababab;
    transition: all 0.3s ease;
  }

  .cross span:first-child {
    transform: rotate(42deg) translate(1px, 2px);
  }

  .cross span:last-child {
    transform: rotate(-50deg) translate(5px, -5px);
  }
}
