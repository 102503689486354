/* width */
#agent-signup-page::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  
  /* Track */
  #agent-signup-page::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   
  /* Handle */
  #agent-signup-page::-webkit-scrollbar-thumb {
    background: #6D6D6D; 
    border-radius: 10px;
  }
  
  
.logo{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 2%;
    top: 0;
}
#agent-signup-page{
    background-color: #FEFEFECC;
    width: 480px;
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 40px 70px 40px 50px;
    align-items: baseline; 
    margin: 30px;
}
#agent-signup-page .agent-signup-form {
    display: flex;
    flex-direction: column;
    align-self: auto !important;
    gap: 30px;
    margin-top: 20px;
}

.agent-signup-title{
 display: flex;
 flex-direction: column;
 align-self: flex-start;
 margin: 0px;
}

.agent-signup-title h2{
  color: #019BBB;
  font-size: 24px;
  margin: 0px;
}
.agent-signup-title p{
 font-size: 16px;
 margin-top: 6px;
 color: #6D6D6D;
}
#agent-signup-page  .agent-signup-form .company-agency{ 
 display: flex;
 flex-direction: column;
 background-color: #ffffff;
 padding: 20px;
 gap: 20px;
 border-radius: 22px;
}

#agent-signup-page .input-field::placeholder {
    color: #ABABAB; 
    font-size: 16px;
}
#agent-signup-page .input-field {
    background-color: #F9F8F8;
    border: 1px solid rgba(53,53,53,0.5);
}

.billing-address input, select {
    padding: 16px 20px !important;
}
#agent-signup-page .agent-signup-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
    background-color: #019BBB;
    border: none;
    color: #fefefe;
    font-size: 18px;
    width: 100%;
}
.signup-btn:hover{
 background-color: #019BBB;
}
#agent-signup-page .agent-sign-login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: -14px 0px 0px 0px;
}
#agent-signup-page .agent-sign-login .already-have-account{
    margin: 0px;
    font-size: 16px;
    color: #6D6D6D;
}
#agent-signup-page .agent-sign-login .contact{
    margin: 60px 0px 0px 0px;
    font-size: 16px;
    color: #6D6D6D;
}
#agent-signup-page .agent-sign-login .button{
    color: #019BBB;
    font-weight: 500;
}

@media (max-width: 768px) {

    .agent-signup-container {
        width: 90%;
        padding: 40px;
        margin-top: 150px;
    }
    .agent-signup-title {
        margin-bottom: 20px;
    }
    .agent-signup-title h2 {
        font-size: 20px;
    }
    .agent-signup-title p {
        font-size: 12px;
    }
    .signup-form input {
        width: 100%;
    }
    .signup-form button {
        width: 100%;
    }
    .logo{
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 2%;
        right: 50%;
    }
}

@media (max-width: 480px) {
    .agent-signup-container {
        padding: 30px;
        width: 80%;
        margin-top: 150px;
    }
    .agent-signup-title {
        margin-top: 20px;
    }
    .agent-signup-title h2 {
        font-size: 18px;
    }
    .agent-signup-title p {
        font-size: 10px;
    }
    .signup-form input {
        width: 100%;
    }
    .signup-form button {
        width: 100%;
    }
    .logo{
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 2%;
        right: 50%;
    }
}

